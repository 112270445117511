'use client';

import { Spinner } from '@/shared/ui/Loaders';

export default function Loading() {
  return (
    <Spinner
      style={{ left: '50%', position: 'absolute', top: '50%' }}
      align='center'
    />
  );
}
